/***** EY Velocity Font Families*********/
@font-face {
    font-family: 'EYInterState-Bold';
    src: url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}

@font-face {
    font-family: 'EYInterState-BoldItalic';
    src: url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.svg#FontName') format('svg'); /* Legacy iOS  */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Italic';
    src: url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Light';
    src: url('./~/assets/fonts/EYInterstate-Light/EYInterstate-Light.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-Light/EYInterstate-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('././~/assets/fonts/EYInterstate-Light/EYInterstate-Light.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-Light/EYInterstate-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-Light/EYInterstate-Light.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}

@font-face {
    font-family: 'EYInterState-LightItalic';
    src: url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Regular';
    src: url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}

$EYInterState-Bold: 'EYInterState-Bold',sans-serif;
$EYInterState-BoldItalic: 'EYInterState-BoldItalic',sans-serif;
$EYInterState-Italic: 'EYInterState-Italic',sans-serif;
$EYInterState-Light: 'EYInterState-Light',sans-serif;
$EYInterState-LightItalic: 'EYInterState-LightItalic',sans-serif;
$EYInterState-Regular: 'EYInterState-Regular',sans-serif;

/***** DEFAULT EY THEME COLORS *********/
$EY_BLACK: #000000;
$EY_WHITE: #FFFFFF;

$EY_DARK_GRAY: #333333;
$EY_GRAY_ALTERNATE: #646464;
$EY_GRAY: #808080;
$EY_GRAY_TINT_1: #999999;
$EY_GRAY_TINT_2: #C0C0C0;
$EY_GRAY_TINT_3: #F0F0F0;
$EY_YELLOW: #FFE600;
$EY_PRIMARY: $EY_YELLOW;
$EY_SECODARY: #666666;

$EY_LINK_BLUE: #336699;
$EY_SPECIAL_RED: #F04C3E;

$EY_BLUE: #00A3AE;
$EY_PURPLE: #91278f;
$EY_GREEN: #2C973E;
$EY_LILAC: #AC98DB;

/***** DEFAULT EY VELOCITY THEME COLORS *********/
$EY_VELOCITY_ALT_BODY_BG: #838383;
$EY_VELOCITY_BODY_BG: #FFFFFF;
$EY_VELOCITY_PAGE_BG: #646464;
$EY_DRIVER_NAV_BG: #636363;
$EY_DRIVER_SUBNAV: #3C3C3C;
$EY_DRIVER_SUBNAV_LEVEL1: #3C3C3C;
$EY_DRIVER_SUBNAV_LEVEL2: #464646;
$EY_DRIVER_SUBNAV_LEVEL3: #4F4F4F;
$EY_DRIVER_SUBNAV_LEVEL4: #4F4F4F;

$font-size-x-lg: 38px;
$font-size-lg: 28px;
$font-size-md: 18px;
$font-size-reg: 14px;

$default_Font_Family: $EYInterState-Regular;
$default_Font_size: $font-size-reg;

$blue-card-color: #41D2FF;
$dark-blue-card-color: #336699;
$green-card-color: #2AD07E;
$black-card-color: $EY_BLACK;
$yellow-card-color: $EY_YELLOW;
$grey-card-color: $EY_DARK_GRAY;
$light-blue-card-color: #4D88C4 ;

$Rag_red: #FF3730;
$Rag_amber: #FFBC00;
$Rag_green: #85CD82;

$listing-search-input-text-color: #BFBFBF;
$listing-search-btn-bg: #BFBFBF;
$listing-row-title : #333333;
$listing_table_top_border: #808080;
$listing_row_bottom_border: #cccccc;
$listing_row_icon_color: #808080;

$font_row_title : 16px;
$font_row_sub_title : 14px;
$font_page_title : 24px;


