@import "./variables";
.list-container {
    background-color: #FAFAFA;
    margin-left: -15px;
    padding: 30px;
    height: 100%;

    .title {
        font-size: 24px;
    }

    input,
    select {
        width: 180px;
        height: 36px;
        padding-left: 5px;
        margin: 0 5px;
    }

    select {
        color: #999999;
    }

    .btn-search {
        background: #808080;
        border: 1px solid #BFBFBF;
        width: 90px;
        min-height: 32px;
        font-size: 14px;
        line-height: 1em;
        height: 36px;
        margin-left: 3px;
        color: #ffffff;
        min-width: 90px;
    }

    .btn-reset {
        background: #FFFFFF;
        border: 1px solid #747480;
        width: 70px;
        min-width: 70px;
        min-height: 32px;
        font-size: 14px;
        line-height: 1em;
        height: 36px;
        margin-left: 3px;
    }

    .search-common {
        /*min-width: 825px;*/
        padding-right: 0px;


        .search-input {
            width: 180px;
            margin-left: 3px;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 10px;
        }

        input {
            margin-bottom: 10px;
            margin-left: 3px;
            margin-right: 0px;
        }
    }

    .search-long {

        .search {
            padding-right: 0px;
        }

        .search-common {
            display: inline-block;
            /*min-width: 750px;*/
            margin-bottom:10px;
        }
    }

    .add-btn {
        min-width: 100px;
    }

    .memberTagContainer {
        .memberTags {
            text-align: center;
            padding: 2px 6px;
            display: inline-block;
            text-transform: uppercase;
            font-size: 10px;
            margin: 5px 5px 5px 0px;
            color: #FFFFFF;
            background: #C0C0C0;
            border-radius: 3px;
        }
    }

    .search-icon {
        font-size: 12px;
        color: #ffffff;
        margin: 0 3px;
    }

    button {
        height: 36px;
    }

    .add-btn {
        text-align: right;
    }

    .result-count-row {
        margin-top: 25px;

        .count {
            color: #393939;
            font-size: 14px;
            margin-left: 5px;
        }

        .countText {
            color: #656565;
        }

        .sort-container {
            text-align: right;

            span {
                padding: 20px 0;
                color: #999999;
                font-size: 14px;
            }

            select {
                background-color: transparent;
                color: #505050;
                display: inline-block;
                width: 170px;
                border-radius: 0;
                border: 0;
                box-shadow: none;
            }
        }
    }

    .lists {
        margin-top: 15px;
        font-size: 16px;

        thead th {
            border-bottom: 2px solid #808080;
            font-weight: normal;
            color: #8F8F8F;
        }

        tbody td {
            border-bottom: 2px solid #CCCCCC;
        }

        .border-right {
            border-right: 2px solid #CCCCCC;
        }

        .border-left {
            border-left: 2px solid #CCCCCC;
        }

        .id {
            min-width: 25px;
        }

        .icons {
            min-width: 130px;

            span {
                font-size: 16px;
                color: #666666;
                padding-left: 8px;
                cursor: pointer;
            }

            span .active {
                color: #189D3E;
            }

            .item-fa-style.disable {
                cursor: not-allowed;
            }
        }

        .index {
            color: #D5D5D5;
        }
    }

    .pagination-style {
        .pagination > .active > a {
            background: #808080 !important;
            border: 1px solid #808080 !important;
        }

        a {
            color: #333333;          
            box-shadow: none !important;
        }
    }

    .btn-group {
        width: 100%;
        border-bottom: 1px solid #E5E5E5;
    }

    .btn-menu {
        border: 0;
        background: #E5E5E5;
        font-size: 14px;
        height: 42px;
        min-width: 80px;

        .count {
            height: 18px;
            min-width: 18px;
            background: #FFFFFF;
            border: 1px solid #C0C0C0;
            border-radius: 12px;
            padding: 2px 7px;
        }
    }

    .btn-menu-active {
        border-bottom: 1px solid #E5E5E5 !important;
        border-left: 1px solid #E5E5E5 !important;
        border-right: 1px solid #E5E5E5;
        border-top: 4px solid $EY_YELLOW;
        background: none;
        background-color: #FAFAFA !important;
        height: 43px;
        margin-bottom: -1px;

        .count {
            border: none;
            background: $EY_YELLOW;
        }
    }   
}
.report-container {
    margin-left: -12px;

    .date-error-box {
        background-color: #F9E5E3;
        padding: 1% 2%;
        border: 1px solid #F04C3E;

        .fa {
            font-size: 18px;
            color: #F04C3E;
        }

        .date-error-message {
            font-family: $EYInterState-Light;
            font-size: 16px;
            padding: 0 1%;
        }
    }
}
.add-container {
    background-color: #FAFAFA;
    margin-left: -15px;
    padding: 30px;
    font-size: 16px;
    font-family: 'EYInterstate-Regular';

    .title {
        font-size: 24px;
    }

    .add-label {
        color: #2E2E38;
    }

    input,
    select {
        min-width: 300px;
        width: 80%;
        height: 35px;
        color: #2E2E38;
        border: 1px solid #CACAD1;
        border-radius: 1px;
        padding-left: 10px;
        padding-right: 10px;
    }

    textarea {
        min-width: 500px;
        width: 100%;
    }

    .form-row {
        margin: 10px 0;
    }

    .action-buttons {
        margin: 20px 0;

        button {
            height: 36px;
            margin-right: 15px;
        }
    }

    .img-browse {
        img {
            height: 100px;
        }

        .btn-browse {
            height: 36px;
            border: 1px solid #CACAD1;
            background-color: transparent;
            margin-left: 10px;
            vertical-align: bottom;
        }

        input[type=file] {
            display: none;
        }
    }

    .selected-list {
        width: 80%;
    }


}
.custom-info-box {
    padding-top: 5px;

    .fa {
        font-size: 16px;
        color: #747480;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .custom-info-text {
        position: relative;
        margin-left: 20px;
    }

    .custom-info-message {
        display: flex;
        position: relative;
        font-size: 12px;
        width: auto;
        overflow: hidden;
    }
}
.view-container {
    background-color: #FAFAFA;
    margin-left: -15px;
    padding: 30px;
    font-size: 14px;
    .title {
        font-size: 24px;
    }
    .view-label {
        color: #CACAD1;
    }
    .form-row {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .value {
        color: #2E2E38;
        line-height: normal;
    }
    .date-container {
        border: 1px solid #CACAD1;
        background-color: #FBFBFD;
        padding: 10px;
        p {
            margin-bottom: 0px;
        }
        .date-label {
            color: #95959C;
            padding: 0 5px;
        }
        .value {
            color: #2E2E38;
            padding: 0 5px;
        }
    }
    .action-buttons {
        button {
            height: 36px;
            margin-right: 15px;
        }
        .btn-dark-gray {
            background: #999999;
        }
    }
}

.btn-dark-gray {
    background: #999999;
}

.btn-browse,
.btn-preview {
    font-size: 14px;
    color: #333333;
    border: 1px solid #666666;
    line-height: 30px !important;
    background-color: #F0F0F0;
}

.navigation-content {
    color: #333333;
    font-size: 13px;
    .breadcrumb-sepeartor {
        color: #C0C0C0;
        padding-right: 5px;
        padding-left: 5px;
    }
    .home-icon {
        color: #808080;
        font-size: 15px;
    }
}

.ref-multi-select-box {
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 2px;
}

.tags-list {
    margin: 0 -5px;
    .tag {
        background-color: #DDDDDD;
        margin: 5px;
        display: inline-block;
        vertical-align: middle;
        padding: 0px 5px;
        .tag-label {
            margin: 2px;
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
            font-family: 'EYInterstate-Regular';
        }
        .tag-close,
        .tag-btn-add {
            margin: 2px;
            font-size: 12px;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            color: #999999;
            .fa {
                color: #000000;
            }
        }
        .tag-btn-add {
            background-color: $EY_YELLOW;
            margin: 0 2px;
            padding: 4px 6px;
            border-radius: 50%;
            line-height: 10px;
        }
    }
    .tag-add {
        cursor: pointer;
        background: transparent;
    }
}

.required {
    color: red;
}

.ref-form-group {}

.form-select{
    font-size:14px;
}

.btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
    pointer-events: auto;
}
/*********************** CHECKBOX ****************************/

.checkbox label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: 0;
    width: 1.4em;
    height: 1.4em; // float: left;
    margin-right: .5em;
}

.checkbox .cr.active {
    background: #DDDDDD;
}

.checkbox .cr .cr-icon {
    color: #666666;
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 20%;
}

.checkbox label input[type="checkbox"] {
    display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
    opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr {
    background-color: #F0F0F0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
    opacity: 1;
}

.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}


.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}
/*********************** CHECKBOX ****************************/

/*** Hot Topic***/
.content-ht-add-overview{
.sub-topic {
    font-size: 18px;
}

    .no-item {
        border: 1px solid #D0D0D0;
        padding: 10px;
        font-size: 16px;

        .exclamation {
            background: $EY_YELLOW;
            width: 22px;
            height: 24px;
            border-radius: 50%;
            font-size: 14px;
            display: inline-block;
            margin-right: 10px;
        }

        i {
            margin-top: 6px;
            margin-left: 9px;
            color: #000;
        }

        button {
            display: inline-block;
            margin-left: 10px;
        }
    }
}
/***End Hot Topic*/


/*********************** CHECKBOX ****************************/

.sub-title-1 {
    font-size: 16px;
    margin: 0px 0px 18px 0px;
    font-weight: bold
}

.container-admin {
    .form-select:focus{
        border-color: #66afe9 !important;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
    }
    .membership {
        .c-token {
            background-color: #E5E5E5 !important;
            color: #333333 !important;
        }

        .c-remove.clear-all {
            display: none !important;
        }

        .selected-list .c-list .c-token {
            background-color: #E5E5E5 !important;
            color: #333333 !important;
            .c-label {
                color: #333333 !important;
            }
        }

        .selected-item {
            background-color: transparent;
        }

        .fa-remove {
            color: #333333 !important;
        }

        .pure-checkbox input[type="checkbox"] + label:before {
            border: 1px solid #a9a9a9 !important;
            color: #666666 !important;
        }

        .pure-checkbox input[type="checkbox"]:checked + label:before {
            border: 1px solid #a9a9a9 !important;
            background-color: #F0F0F0 !important;
        }

        .pure-checkbox input[type="checkbox"] + label:after {
            background-color: #F0F0F0 !important;
            border-color: #666666 !important;
        }
        .selected-list .c-btn {
            box-shadow: none !important;
            padding:7px!important;
            appearance: auto !important;
            border: 1px solid #ccc !important;
            font-size: inherit !important;
        }
    }

    .pagination > .disabled > span,
    .pagination > .disabled > span:hover,
    .pagination > .disabled > span:focus,
    .pagination > .disabled > a,
    .pagination > .disabled > a:hover,
    .pagination > .disabled > a:focus {
      color: #777777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
      
      pointer-events: auto;
    }
}
.multiselect-container
{
    .membership {
        .selected-list .c-angle-down, 
        .selected-list .c-angle-up {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }
}

#keywordSearch,
.m-r-4 {
    margin-right: 4px;
}
.m-r-3 {
    margin-right: 3px;
}
.display-block{
    display: block;
}
.display-contents{
    display: contents;
}

.list-group-item.disabled {
    cursor: not-allowed;
    pointer-events:all;
}